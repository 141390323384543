<template>

    <div class="row mobile--scrolling  d-flex align-items-stretch" tabindex="-1" v-if="contents !== null">
        <ratgeber-kategorie-card :item="category" :color="category.color" />
        <ratgeber-card style="width: 320px; min-width:320px;" v-for="(content, index) in contents" :key="index"
            :content="content" :category="category.name" />
    </div>

</template>

<script>

import { getContentsOfType } from '@/api/content';

export default {
    name: "ratgeberRow",
    components: {
        RatgeberKategorieCard: () => import('@/components/cards/RatgeberKategorie.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
    },
    props: {
        category: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            dataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 4,
                    type: "teaser",
                    selectedAppId: this.$appId,
                    keyword: this.category,
                    selectRandomly: true,
                    selectedContentTypeId: this.$ratgeberId,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
        }
    },
    created() {
        this.getContentsForCategory();
    },
    computed: {
        cat() {
            if (this.category !== null) {
                return this.category.name;
            }
            return null;
        },
        contents() {
            if (this.dataSet.data != null && this.dataSet.data.length > 0) {
                /*hard-coded Langcode*/
                return this.dataSet.data.length > 0 ? this.dataSet.data : null;
            }
            return null;
        }
    },
    methods: {
        async getContentsForCategory() {
            if (this.cat !== null) {
                this.dataSet.query.keyword = '"' + this.category.name + '"';
                const { limit, page } = this.dataSet.query;
                const { data, meta } = await getContentsOfType(this.$ratgeberId, this.dataSet.query);
                this.dataSet.data = data;
                this.dataSet.data.forEach((element, index) => {
                    element['index'] = (page - 1) * limit + index + 1;
                });
                this.dataSet.meta = meta;
                this.dataSet.total = meta.total;
            }
        }
    }
}
</script>
